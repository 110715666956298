.border-purple-a {
  border: 1px $wGreen solid;
}
.h-fit-content {
  height: fit-content;
}
.h-max-content {
  width: max-content;
}
.h-3-5-r {
  height: 3.5rem;
}
//
.vh-75 {
  height: 75vh !important;
}
.t-rotate-180 {
  transform: rotate(180deg);
}

.h-max-75 {
  max-height: 75%;
}

// POSITION

.r-1 {
  right: 1rem;
}

.t-1 {
  top: 1rem;
}

.p-0-0-0-0 {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

// Z-INDEX

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

.z-4 {
  z-index: 4;
}

.z-5 {
  z-index: 5;
}

.z-6 {
  z-index: 6;
}

.z-7 {
  z-index: 7;
}

.z-8 {
  z-index: 8;
}

.z-9 {
  z-index: 9;
}

.z-10 {
  z-index: 10;
}

.z-100 {
  z-index: 100;
}

.z-101 {
  z-index: 101;
}

.z-1000 {
  z-index: 1000;
}

.z-7000 {
  z-index: 7000;
}

.z-8000 {
  z-index: 8000;
}

.z-8500 {
  z-index: 8500;
}

.z-9000 {
  z-index: 9000;
}

.z-10000 {
  z-index: 10000;
}

.z-99999 {
  z-index: 99999;
}

// OVERFLOW

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

// UTILS

.w-fit-content {
  width: fit-content;
}

.object-fit-cover {
  object-fit: cover;
}

.rotate--90 {
  transform: rotate(-90deg);
}

.absolute-center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.min-h-100 {
  min-height: 100% !important;
}

.w-95 {
  width: 95% !important;
}

.w-90 {
  width: 90% !important;
}

.w-85 {
  width: 85% !important;
}

.w-80 {
  width: 80% !important;
}

.w-70 {
  width: 70% !important;
}

.w-65 {
  width: 65% !important;
}

.w-60 {
  width: 60% !important;
}

.w-55 {
  width: 55% !important;
}

.w-50 {
  width: 50% !important;
}

.w-45 {
  width: 45% !important;
}

.w-40 {
  width: 40% !important;
}

.w-35 {
  width: 35% !important;
}

.w-30 {
  width: 30% !important;
}

.w-20 {
  width: 20% !important;
}

.w-15 {
  width: 15% !important;
}

.w-10 {
  width: 10% !important;
}
