.expand-enter-active,
.expand-leave-active {
  transition: opacity 0.2s ease-in-out, height 0.3s ease;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  height: 0;
  opacity: 0;
}

// TRANSITIONS
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter, .fade-leave-to
  /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
