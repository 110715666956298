// GENERAL
.error {
  color: $wAlert !important;
  label {
    color: $wAlert;
  }
  .field-input {
    border: 1px $wAlert solid !important;
  }
  .multiselect__tags {
    border: 1px $wAlert solid !important;
  }
  /* .multiselect {
      color: $wAlert !important;
    } */
}
.green {
  .field-input {
    border: 1px $klausPurple solid !important;
  }
  .multiselect__tags {
    border: 1px $klausPurple solid !important;
  }
}
