//@import "/fonts/Brown/stylesheet.css";

/* @font-face {
  //font-display: swap;

  font-family: "brown";
  font-style: normal;
  font-display: auto;
  //font-weight: 100;
  src: local("wbrown"), url("/fonts/Brown/brown-thin.ttf") format("truetype");
} */
@font-face {
  font-family: "brown-bold-italic";
  font-style: normal;
  font-display: auto;
  src: local("brown-bold-italic"),
    url("/fonts/Brown/BROWN-BOLDITALIC.ttf") format("truetype");
}
@font-face {
  font-family: "brown-light";
  font-style: normal;
  font-display: auto;
  src: local("brown-light"),
    url("/fonts/Brown/BROWN-LIGHT.ttf") format("truetype");
}
@font-face {
  font-family: "brown-light-italic";
  font-style: normal;
  font-display: auto;
  src: local("brown-light-italic"),
    url("/fonts/Brown/BROWN-LIGHTITALIC.ttf") format("truetype");
}
@font-face {
  font-family: "brown";
  font-style: normal;
  font-display: auto;
  src: local("brown"), url("/fonts/Brown/BROWN-REGULAR.ttf") format("truetype");
}
@font-face {
  font-family: "brown-italic";
  font-style: normal;
  font-display: auto;
  src: local("brown-italic"),
    url("/fonts/Brown/BROWN-REGULARITALIC.ttf") format("truetype");
}
@font-face {
  font-family: "brown-thin-italic";
  font-style: normal;
  font-display: auto;
  src: local("brown-thin-italic"),
    url("/fonts/Brown/BROWN-THINITALIC.ttf") format("truetype");
}
@font-face {
  font-family: "brown-thin";
  font-style: normal;
  font-display: auto;
  src: local("brown-thin"),
    url("/fonts/Brown/brown-thin.ttf") format("truetype");
}

.brown-white-bold-thin {
  font-family: "brown-thin";
  font-weight: 600;
  color: white;
}
.brown-white-bold {
  font-family: "brown";
  font-weight: 600;
  color: white;
}
.brown-white-regular {
  font-family: "brown";

  color: white;
}
.brown-green-regular {
  font-family: "brown";

  color: $wGreen;
}
.brown-green-bold {
  font-family: "brown";
  font-weight: 600;
  color: $wDarkGreen;
}
.brown-green-dark-regular {
  font-family: "brown";

  color: $wDarkGreen;
}
.brown-wGreyMedium {
  font-family: "brown";

  color: $wGreyMedium;
}
.brown-wGreyMedium {
  font-family: "brown";

  color: $wGreyMedium;
}

.brown-wGreyMedium-bold {
  font-family: "brown-thin";
  font-weight: 600;
  color: $wGreyDark;
}

.brown-wGreyMedium-thin {
  font-family: "brown-thin";

  color: $wGreyDark;
}

p {
  font-size: 0.95rem;
}

h2 {
  line-height: 1;
  font-weight: 600;
}

// TEXT SIZES

.font-rem-0-5 {
  font-size: 0.5rem;
}
.font-rem-0-6 {
  font-size: 0.6rem;
}
.font-rem-0-65 {
  font-size: 0.65rem;
}
.font-rem-0-7 {
  font-size: 0.7rem;
}
.font-rem-0-75 {
  font-size: 0.75rem;
}
.font-rem-0-8 {
  font-size: 0.8rem;
}
.font-rem-0-85 {
  font-size: 0.85rem;
}
.font-rem-0-9 {
  font-size: 0.9rem;
}
.font-rem-1 {
  font-size: 1rem;
}
.font-rem-1-1 {
  font-size: 1.1rem;
}

.font-rem-1-15 {
  font-size: 1.15rem;
}
.font-rem-1-2 {
  font-size: 1.2rem;
}

.font-rem-1-25 {
  font-size: 1.25rem;
}

.font-rem-1-5 {
  font-size: 1.5rem;
}

.font-rem-1-75 {
  font-size: 1.75rem;
}

.font-rem-2 {
  font-size: 2rem;
}

// TEXT WEIGHTS

.font-w-300 {
  font-weight: 300;
}

.font-w-400 {
  font-weight: 400;
}

.font-w-500 {
  font-weight: 500;
}

.font-w-600 {
  font-weight: 600;
}

.font-w-700 {
  font-weight: 700;
}

.font-w-800 {
  font-weight: 800;
}

// TEXT UTILITIES

.ls-1 {
  letter-spacing: 1px;
}

.ls-2 {
  letter-spacing: 2px;
}

.ls-3 {
  letter-spacing: 3px;
}
