@import "node_modules/bootstrap/scss/bootstrap";

/* // VARIABLES

@import "~@/styles/variables.scss";
 */
// FONTS

@import "~@/styles/fonts.scss";

// COLORS

@import "~@/styles/colors.scss";

// INPUTS

@import "~@/styles/inputs.scss";

// TRANSITION

@import "~@/styles/transition.scss";

// UTILS

@import "~@/styles/utils.scss";

// BOOTSTRAP

/* @import "~@/styles/bootstrap.scss"; */

//ANCHORS BUTTONS AND LINKS

@import "~@/styles/buttons.scss";

// HTML BODY APP * AND GENERAL

* {
  //transition: all 0.3s ease;
  //border: 1px solid red;
  box-sizing: border-box;
  outline: none;
  :focus {
    outline: none !important;
  }
}

.text-left {
  text-align: left !important;
}
$navHeight: 4.4rem;
$headHeight: 5.8rem;

.backButton {
  top: 1rem;
  left: 1rem;
}
.head-bar-visible {
  padding-top: $headHeight;
}

.nav-bar {
  position: fixed;
  left: 0;
  right: 0;
  //top: 0;
  bottom: 0;
  box-shadow: 3pt 0pt 9pt grey;
  height: $navHeight;
  //transition: all 0.3s ease-in-out;
}

.visible-nav-bar {
  padding-bottom: $navHeight;
}

.layer-hei {
  height: $headHeight;
}
.nav-bar-ab {
  position: fixed;
  left: 0;
  right: 0;
  //top: 0;
  bottom: 0;
  box-shadow: 3pt 0pt 9pt grey;
  height: $navHeight;
  //transition: all 0.3s ease-in-out;
}
.nav-bar-ab-old {
  //position: absolute;
  left: 0;
  right: 0;
  //top: 0;
  bottom: 0;
  box-shadow: 3pt 0pt 9pt grey;
  height: $navHeight;
  //transition: all 0.3s ease-in-out;
}
.head-bar-ab {
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  box-shadow: 0pt 3pt 6pt grey;
  height: $headHeight;
  overflow: hidden;
  //transition: all 0.3s ease-in-out;
}
.head-bar {
  overflow: hidden;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  box-shadow: 0pt 3pt 6pt grey;
  height: $headHeight;
  //transition: all 0.3s ease-in-out;
}

html {
  --scrollbarBG: #f0f0fa;
  --thumbBG: $wGreen;
  scroll-behavior: smooth;
  text-align: center;
  height: 100%;
}
*::-webkit-scrollbar {
  width: 11px;
  height: 11px;
}
* {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
*::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
*::-webkit-scrollbar-track:horizontal {
  background: var(--scrollbarBG);
}
*::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}
*::-webkit-scrollbar-thumb:horizontal {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

body {
  margin: 0;
  height: 100%;
}

#app {
  font-family: "brown", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  border: none;
}

// SVG FILL

.fill-dark {
  fill: $wGreyLight;
  path {
    fill: $wGreyLight;
  }
}
.fill-light {
  fill: $light;
  path {
    fill: $light;
  }
}

.fill-purple {
  fill: $wGreen;
  path {
    fill: $wGreen;
  }
}

.plyr {
  width: 100% !important;
  height: 100% !important;
  min-width: auto !important;
}
.plyr__poster {
  background-size: cover !important;
}
.plyr__video-wrapper {
  width: 100% !important;
  height: 100% !important;
}

:root {
  --plyr-color-main: #4d49ef !important;
}
