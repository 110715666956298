.bg-baby-purple {
  background-color: $wLightGreen;
}
.bg-gray-light {
  background-color: #f5f6f8;
}
.bg-gray {
  background-color: #b7b7b7;
}

.bg-gray-dark {
  background-color: $wGreyDark;
}
.bg-green {
  background-color: $wGreen;
}
.bg-white {
  background-color: white;
}
.bg-dark-green {
  background-color: $wDarkGreen;
}
.bg-orange {
  background-color: #efc59f;
}
.bg-red {
  background-color: $wRed;
}
.bg-black-light {
  background-color: #b7b7b7;
}
.color-dark-green {
  color: $wDarkGreen;
}
.color-white {
  color: white;
}
.color-light-green {
  color: $wGreen;
}
.color-light-red {
  color: $wAlert;
}

.bg-purple {
  background-color: $wGreen;
}

.bg-purple-50 {
  background-color: rgba($color: $wGreen, $alpha: 0.5);
}
.bg-gray-75 {
  background-color: rgba($color: $wGreyDark, $alpha: 0.75);
}
.bg-dark-purple-75 {
  background-color: rgba($color: $wDarkGreen, $alpha: 0.75);
}
.bg-dark-purple-25 {
  background-color: rgba($color: $wDarkGreen, $alpha: 0.25);
}
.bg-dark-purple-linear {
  background-image: linear-gradient(
    155deg,
    rgba($color: $wDarkGreen, $alpha: 1),
    rgba($color: $wGreen, $alpha: 1)
  );
}
.bg-dark-purple-linear-90 {
  background-image: linear-gradient(
    155deg,
    rgba($color: $wDarkGreen, $alpha: 0.9),
    rgba($color: $wGreen, $alpha: 0.9)
  );
}
.bg-purple-light-purpple-radial {
  background: $wGreen;
  background: radial-gradient(circle, $wLightGreen 0%, $wGreen 75%);
}
.text-red {
  color: $wRed;
}
.text-grey-medium {
  color: $wGreyMedium;
}

.text-grey-dark {
  color: $wGreyDark;
}

.text-purple {
  color: $wGreen;
}

.fill-purple {
  fill: $wGreen;
}

.fill-white {
  fill: white !important;
  svg {
    fill: white !important;
  }
}
.fill-nn {
  fill: black !important;
  svg {
    fill: black !important;
  }
}
