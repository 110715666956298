// ANCHOR

a {
  font-family: "Brown", Helvetica, Arial, sans-serif;
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: none;
  }
}
button {
  appearance: none !important;
  border: none;
  outline: none;
  color: inherit;
  background-color: transparent;
  font-family: "Brown", Helvetica, Arial, sans-serif;
}

.pointer {
  cursor: pointer;
}

*:focus {
  outline: none;
}

// BUTTONS

.plain-underlined {
  background-color: transparent;
  color: $wGreen;
  border-bottom: 1px solid $wGreen;
  font-weight: 600;
  font-size: 1rem;
}
